.navbar {
  background: linear-gradient(150deg, #bbd3f8 0%, #248eeb 100%);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}

.logo {
  max-height: 40px;
  width: auto;
  object-fit: contain;
  margin-right: 15px;
  border-radius: 4px;
  margin-bottom: -5px;
}

.navbar-brand {
  color: rgb(30, 1, 1);
  font-weight: bold;
  font-size: 1.32rem;
  display: flex;
  align-items: center;
}

.nav-link {
  font-weight:600;
  color: rgba(19, 2, 2, 0.85);
  transition: all 0.3s ease;
}

.nav-link:hover {
  color: rgb(4, 24, 73);
  transform: translateY(-2px);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.navbar-toggler {
  border-color: rgba(255, 255, 255, 0.5);
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}