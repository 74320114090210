body {
    background-color: #f8f9fa;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

/* General container styles */
.container {
    padding: 4px;
}

/* Centering h2 and p on mobile */
@media (max-width: 768px) {
    h2 {
        text-align: center;
        margin-top: 20px;
        font-size: 1.8rem;
    }

    p {
        text-align: center;
        font-size: 1rem;
        margin-bottom: 20px;
    }

    .col-md-6 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .row {
        flex-direction: column;
    }

    /* Ensure QueryForm centers vertically */
    .col-md-6:last-child {
        margin-top: 20px;
    }
}