.faq-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .faq-container h1 {
    color: #03124a;
    font-weight: 600;
  }
  
  .card-header {
    background-color: #f8f9fa;
    transition: background-color 0.3s ease;
  }
  
  .card-header:hover {
    background-color: #e9ecef;
  }
  
  .card-header h5 {
    font-weight: 400;
    color: #020910;
  }